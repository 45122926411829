import { importProvidersFrom } from '@angular/core';
import { Route } from '@angular/router';
import {
  privateActivateGuard,
  privateMatchGuard,
  publicActivateGuard,
  publicMatchGuard,
} from '@carwash-project/modules/data-access/auth';
import { BranchState } from '@carwash-project/modules/data-access/branch';
import { ClientPlansState } from '@carwash-project/modules/data-access/client-plans';
import { GiftCardsState } from '@carwash-project/modules/data-access/gift-cards';
import { PlansState } from '@carwash-project/modules/data-access/plans';
import { SurveysState } from '@carwash-project/modules/data-access/surveys';
import { UserState } from '@carwash-project/modules/data-access/user';
import { NgxsModule } from '@ngxs/store';

export const appRoutes: Route[] = [
  {
    path: 'main',
    providers: [importProvidersFrom([NgxsModule.forFeature([UserState, BranchState])])],
    loadChildren: () => import('./routes/private.routes'),
    canActivate: [privateActivateGuard],
    canMatch: [privateMatchGuard],
  },
  {
    path: 'view-user',
    providers: [importProvidersFrom([NgxsModule.forFeature([UserState, BranchState])])],
    loadChildren: () => import('./routes/public.routes'),
  },
  {
    path: 'survey',
    providers: [importProvidersFrom([NgxsModule.forFeature([SurveysState])])],
    loadChildren: () =>
      import('@carwash-project/modules/feature-user/survey').then(
        (m) => m.surveyRoutes
      ),
  },
  {
    path: '',
    providers: [
      importProvidersFrom([
        NgxsModule.forFeature([PlansState, ClientPlansState, GiftCardsState, BranchState]),
      ]),
    ],
    loadChildren: () =>
      import('@carwash-project/modules/feature-user/auth').then(
        (m) => m.authRoutes
      ),
    canActivate: [publicActivateGuard],
    canMatch: [publicMatchGuard],
  },
  {
    path: '**',
    redirectTo: 'main',
    pathMatch: 'full',
  },
];
